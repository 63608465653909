<template>
    <v-container fluid>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-btn icon
                           light
                           @click="$router.go(-1)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h1>{{user.firstName}} {{user.lastName}}</h1>
                    <span class="ml-5" v-for="item in user.userRoles" :key="item.name">
                        {{item.name}}
                    </span>
                </v-col>
                <v-col align="end">
                    <v-btn color="success"
                           elevation="2"
                           large
                           @click="editUser()">
                        {{ $t('common_edit') }}
                    </v-btn>
                </v-col>
            
            </v-row>
        </v-container>

        <v-card style="background-color: #f3f2e8;">
            <v-list two-line style="background-color: #f3f2e8;">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="demex_primary">
                            mdi-phone
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{user.phone}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="demex_primary">
                            mdi-email
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{user.email}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="demex_primary">
                            mdi-cog
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Övrigt</v-list-item-title>
                        <v-list-item-subtitle>Id {{user.id}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-container>
</template>

<style scoped>
    .style-fix {
        text-decoration: none !important;
    }
</style>

<script>
    import { mapActions } from 'vuex';
    import accountService from '@/services/accountService';
    import router from '@/router';

    export default {
        name: 'profile',
        props: { userId: Number },
        components: {
        },
        data: () => ({
            selectedRoles: [],

            editUserDialog: false,

            emailRules: [
                v => /.+@.+/.test(v) || 'E-post är inte giltig',
            ],

            user: {},
            avaiableRoles: [],
        }),
        methods: {
            editUser() {
                router.push({ name: 'editUser', params: { userId: parseInt(this.userId) } })

                //<router-link : to="{name: 'editUser', params: {userId: parseInt(user.id)}}" class="style-fix">
            },
            ...mapActions({
                setUserInfo: 'login/SET_USER_INFO'
            }),
        },
        mounted() {
            accountService.getById("GetUserById", this.userId)
                .then((data) => {
                    this.user = data;
                    for (var i = 0; i < this.user.userRoles.length; i++) {
                        this.selectedRoles.push(this.user.userRoles[i].name)
                    }
                });

            accountService.getRoles()
                .then((data) => {
                    this.avaiableRoles = data;
                });
        },
    }
</script>